import { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'
import GQLPaginatedTable from 'components/GQLPaginatedTable'
import Badge from 'components/Badge'
import { UserModel } from 'types/models'
import { TableSchema } from 'components/Table'
import { USER_ROLE } from 'types/enums'
import { getCaseRole } from 'utils/funcs'
import CasesSearch, { PresetValues } from 'search/CasesSearch'
import useBufferedQuery from 'hooks/useBufferedQuery'
import { gql } from '@apollo/client'
import { GQLCaseType, GQLConnection } from 'types/gql'

const query = gql`
	query ($pageNumber: Int!, $pageSize: Int!, $orderBy: CaseOrderBy!, $search: CaseSearch) {
		Cases(pageNumber: $pageNumber, pageSize: $pageSize, orderBy: $orderBy, search: $search) {
			items {
				id
				caseNumber
				applicantName
				accClaimNumber
				type {
					id
					readable
				}
				status {
					id
					readable
				}
				organization {
					id
				}
				applicant {
					id
					user {
						id
						fullName
					}
					parties {
						id
					}
				}
				caseManager {
					id
					user {
						id
					}
					parties {
						id
					}
				}
				reviewer {
					id
					user {
						id
					}
					parties {
						id
					}
				}
				mediator {
					id
					user {
						id
					}
					parties {
						id
					}
				}
				peerReviewer {
					id
					user {
						id
					}
					parties {
						id
					}
				}
				respondent {
					id
					user {
						id
					}
					parties {
						id
					}
				}
			}
			pageInfo {
				pageNumber
				pageSize
				totalPages
				totalCount
			}
		}
	}
`

interface UserTabCasesProps {
	user: UserModel
}

const UserTabCases: FC<UserTabCasesProps> = ({ user }) => {
	const location = useLocation()

	const state = location?.state as {
		cases_search?: any
		cases_page?: number
		cases_pageSize?: number
	} | null

	const { advocate, caseManager, applicant, reviewer, mediator, respondent, peerReviewer, ...search } =
		state?.cases_search || {}

	const {
		data: cases,
		loading: casesLoading,
		refetch,
	} = useBufferedQuery<{ Cases: GQLConnection<GQLCaseType> }>(query, {
		variables: {
			orderBy: 'created_DESC',
			pageNumber: state?.cases_page || 1,
			pageSize: state?.cases_pageSize || 5,
			search: {
				...search,
				OR: {
					...(applicant && advocate
						? {
								applicant: {
									user: applicant,
									parties: advocate,
								},
						  }
						: {}),
					...(applicant && !advocate
						? {
								applicant: {
									user: applicant,
								},
						  }
						: {
								applicant: {
									user: {
										email: user?.email,
									},
								},
						  }),
					...(!applicant && advocate
						? {
								applicant: {
									parties: advocate,
								},
						  }
						: {
								applicant: {
									parties: {
										email: user?.email,
									},
								},
						  }),
					...(!applicant && !advocate
						? {
								applicant: {
									user: {
										email: user?.email,
									},
									parties: {
										email: user?.email,
									},
								},
						  }
						: {}),
					...(caseManager
						? { caseManager: { user: { id: caseManager } } }
						: { caseManager: { user: { id: user?.id } } }),
					...(reviewer ? { reviewer: { user: { id: reviewer } } } : { reviewer: { user: { id: user?.id } } }),
					...(mediator ? { mediator: { user: { id: mediator } } } : { mediator: { user: { id: user?.id } } }),
					...(respondent
						? { respondent: { user: { id: respondent } } }
						: { respondent: { user: { id: user?.id } } }),
					...(peerReviewer
						? { peerReviewer: { user: { id: peerReviewer } } }
						: { peerReviewer: { user: { id: user?.id } } }),
				},
			},
		},
	})

	// const { resolvedData: cases, isFetching: casesLoading } = usePaginatedData<CaseModel>('/Cases', casesPaginationState)

	const caseSchema: TableSchema<GQLCaseType> = {
		cols: [
			{
				title: 'Case Number',
				value: (x) => (
					<Link to={`/cases/${x.id}`} className="anchor">
						{x.caseNumber}
					</Link>
				),
				width: 'minmax(auto, max-content)',
			},
			{
				title: 'ACC Claim #',
				value: (x) => x.accClaimNumber || '-',
				truncate: true,
			},
			{
				title: 'Applicant',
				value: (x) => x.applicant?.user?.fullName || x.applicantName || '-',
				truncate: true,
			},
			{
				title: 'Role',
				value: (x) => (user ? getCaseRole(x, user) : '-'),
				truncate: true,
			},
			{
				title: 'Type',
				value: (x) => x.type?.readable,
				truncate: true,
			},
			{
				title: 'Status',
				value: (x) => (
					<Badge status={x.status?.id} size="sm">
						{x.status?.readable}
					</Badge>
				),
				width: 'minmax(auto, max-content)',
			},
		],
	}

	const CasesPresets: PresetValues = {}

	if (user?.roles.find((x) => x.id === USER_ROLE.CaseManager)) {
		CasesPresets.caseManager = { value: user.id, locked: false }
	}
	if (user?.roles.find((x) => x.id === USER_ROLE.Reviewer)) {
		CasesPresets.reviewer = { value: user.id, locked: false }
	}
	if (user?.roles.find((x) => x.id === USER_ROLE.PeerReviewer)) {
		CasesPresets.peerReviewer = { value: user.id, locked: false }
	}
	if (user?.roles.find((x) => x.id === USER_ROLE.Respondent)) {
		CasesPresets.respondent = { value: user.id, locked: false }
	}
	if (user?.roles.find((x) => x.id === USER_ROLE.Mediator)) {
		CasesPresets.mediator = { value: user.id, locked: false }
	}

	return (
		<GQLPaginatedTable
			set={cases?.Cases}
			schema={caseSchema}
			isLoading={casesLoading}
			refetch={refetch}
			paginationPrefix="cases_"
			search={(props) => <CasesSearch {...props} presetValues={user ? CasesPresets : undefined} />}
		/>
	)
}

export default UserTabCases
